<template>
  <div class="four-steps">
    <div class="step-left">
      <div :class="['left-item',currentIndex === index?'is-active':'']" v-for="(item,index) in stepArr"
        :key="index" @click="currentIndex = index">
        <p class="step-num">{{"0"+(index+1)}}</p>
        <p class="step-title">{{item.name}}</p>
        <img v-if="item.is_complete" class="is-complete" src="../assets/images/is-complete-icon.png" />
      </div>
    </div>
    <div class="step-right" :class="{top: stepArr[currentIndex].children.length < 3}">
      <template v-if="stepArr[currentIndex].children.length">
        <div class="right-item" v-for="(item,index) in stepArr[currentIndex].children" :key="index">
          <el-image class="item-icon" :src="item.icon"></el-image>
          <p style="min-width: 98px">{{item.title}}</p>
          <p class="item-desc">{{item.name}}</p>
          <el-button type="text"
            :style="!item.is_complete?'':item.is_complete === 1?'color: #13CE66':'color: #C0C4CC'"
            @click="handleJump(item)">
            {{!item.is_complete?'立即前往':item.is_complete === 1?'已完成':'敬请期待'}}
          </el-button>
        </div>
      </template>
      <div v-if="!stepArr[currentIndex].children.length" class="right-item-s">
        <p class="item-title">拖拽装修组件，轻松打造个性页面</p>
        <div class="item-content">
          <div class="content-item" v-for="(item,index) in contentArr" :key="index">
            <el-image :class="item.name?'item-img':'item-icon'" :src="item.icon"></el-image>
            <div class="item-name" v-if="item.name">{{item.name}}</div>
          </div>
        </div>
        <el-button type="primary" @click="jumpRenovation">立即前往</el-button>
      </div>
      <img v-if="!stepArr[currentIndex].children.length" class="item-bg" src="../assets/images/item-bg.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stepArr: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      contentArr: [
        { icon: require("../assets/images/shuazi.png"), name: "选择装修组件" },
        { icon: require("../assets/images/xiayibu.png"), name: "" },
        { icon: require("../assets/images/upgrade.png"), name: "添加业务数据" },
        { icon: require("../assets/images/xiayibu.png"), name: "" },
        { icon: require("../assets/images/book.png"), name: "调整页面效果" },
        { icon: require("../assets/images/xiayibu.png"), name: "" },
        { icon: require("../assets/images/file.png"), name: "保存发布结果" },
      ],
    };
  },
  methods: {
    handleJump(data) {
      if (data.jump_link && !data.is_complete)
        this.$router.push({ name: data.jump_link });
    },
    //跳转页面装修
    jumpRenovation() {
      this.$router.push({ name: "AppDesignIndex" });
    },
  },
};
</script>

<style lang="scss" scoped>
.four-steps {
  display: flex;
  margin-top: 27px;

  .step-left {
    flex: 5;
    min-width: 280px;
    z-index: 2;
    .left-item {
      height: 25%;
      padding: 15px 27px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .step-num {
        // font-weight: bold;
        font-size: 24px;
        color: #3a3a3a;
        margin-right: 9px;
      }

      .step-title {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }

      .is-complete {
        width: 24px;
        height: 24px;
        margin-left: 12px;
      }

      &:hover {
        background-color: rgba(249, 250, 253, 0.8);
      }
    }

    .is-active {
      background-color: rgba(249, 250, 253, 0.8);
    }
  }

  .step-right {
    max-height: 240px;
    flex: 7;
    z-index: 2;
    min-width: 534px;
    padding: 0 19px;
    background: #ffffff;
    box-shadow: 0px 10px 18px 0px rgba(197, 215, 247, 0.5);
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &.top {
      .right-item {
        flex: unset;
      }
    }

    .item-bg {
      width: 215px;
      height: 80px;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    .right-item {
      flex: 1;
      display: flex;
      align-items: center;

      .item-icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        flex-shrink: 0;
      }

      .item-desc {
        min-width: 230px;
        margin-right: 17px;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }

      .el-button {
        margin-left: auto;
      }

      p + p {
        margin-left: 24px;
      }
    }

    .right-item-s {
      padding: 24px 0;
      .item-title {
        font-size: 14px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 24px;
      }
      .item-content {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .content-item {
          text-align: center;
          .item-img {
            width: 57px;
            height: 57px;
          }
          .item-icon {
            width: 28px;
            height: 28px;
          }
          .item-name {
            width: 84px;
            font-size: 14px;
            color: #606266;
            margin-top: 12px;
          }
        }

        .content-item + .content-item {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
