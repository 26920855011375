<template>
  <div v-if="is_show" class="content-box">
    <el-dialog
      :visible="is_show"
      width="740px"
      max-height="600px"
      class="dialog-vertical"
      @close="close()"
      :show-close="false"
      append-to-body
    >
      <template #title>
        <div class="title-box">
          <div class="title">更新公告</div>
          <div class="tags">
            <el-tag>{{ content.create_time }}</el-tag>
            <el-tag>{{ content.title }}</el-tag>
          </div>
        </div>
      </template>
      <!-- 内容 -->
      <el-scrollbar ref="scrollMenuRef" class="scrollMenuBox">
        <div class="content" v-html="content.content"></div>
      </el-scrollbar>
      <span slot="footer">
        <el-button @click="close()" size="small">关闭</el-button>

        <el-button
          type="primary"
          @click="submit"
          :loading="loading"
          size="small"
        >
          查看
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "EntranceDialog",
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    // 弹窗内容
    content: {
      type: Object,
      detault: () => {},
    },
  },
  components: {},
  created() {},
  computed: {
    // 显示框
    is_show: {
      get() {
        return this.$props.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      form: {},
      rules: {},
      loading: false,
    };
  },
  methods: {
    close() {
      this.is_show = false;
    },
    submit() {
      window.open(this.$props.content.link);
    },
  },
};
</script>
<style lang="scss" scoped>
.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: #1a1a1a;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .tags {
    // padding-right: 50px;
    .el-tag {
      margin-right: 5px;
    }
  }
}
::v-deep .content {
  width: 100%;
  img {
    width: 100%;
  }
}
.scrollMenuBox {
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
}
/* element滚动条组件 隐藏水平滚动条 */
::v-deep .sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .is-horizontal {
  display: none;
}
</style>
