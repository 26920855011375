<template>
  <el-dialog
    title="自定义数据"
    :visible.sync="openDialog"
    width="734px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel"
    append-to-body
  >
    <div class="selected">
      <p class="label">已选择数据类型：</p>
      <draggable
        style="width: calc(100% - 220px)"
        v-model="Selected"
        :sort="true"
        forceFallback="true"
        group="view_index"
        animation="300"
        @end="onEnd"
      >
        <transition-group class="tag">
          <div
            class="tag-item"
            v-for="(tag, index) in Selected"
            :key="tag.view_index"
          >
            <el-tag closable @close="handleClose(tag, index)">
              {{ tag.name }}
            </el-tag>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div v-if="Selected && Selected.length" class="selected medium-form" style="margin-top: 0">
      <div class="el-form-item">
        <p class="label el-form-item__label"></p>
        <p class="el-form-item__content" style="margin-left: 120px;">
          <span class="info"><i class="el-icon-warning" />鼠标拖拽调整顺序</span>
        </p>
      </div>
    </div>
    <div class="head-filter">
      <el-input
        size="medium"
        v-model="keyword"
        placeholder="搜索"
        @change="handleFilter"
      >
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list">
      <el-table
        :data="SelectList"
        class="thead-light"
        style="width: 100%"
        :height="250"
        ref="multipleTable"
        @row-click="handleCurrentChange"
        v-loading="loading"
        :span-method="objectSpanMethod"
      >
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-if="th.prop === 'selected'"
                v-model="scope.row[th.prop]"
                disabled
              >
              </el-checkbox>
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="select-btn">
      <el-button @click="handleSelectDefault">
        <el-checkbox
          style="margin-right: 4px"
          v-model="isDefaultSelect"
        ></el-checkbox
        >默认选择
      </el-button>
      <el-button type="primary" @click="clearSelected">清空选择</el-button>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="saveLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { StatusList, saveSelected } from '../api/index-config'
import draggable from 'vuedraggable'
export default {
  components: { draggable },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: '数据类型', prop: 'title', minWidth: 70 },
        { label: '选择', prop: 'selected', minWidth: 40 },
        { label: '数据名称', prop: 'name', minWidth: 90 },
        { label: '数据描述', prop: 'introduction', minWidth: 200 },
      ],
      SelectList: [],
      Selected: [],
      defaultSelected: [],
      isDefaultSelect: false, //是否默认选择
      getSelected: true,
      keyword: '', //关键词
      spanArr: [],
      saveLoading: false,
    }
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getDataList()
      } else {
        this.getSelected = true
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.loading = true
      StatusList({ keyword: this.keyword })
        .then((res) => {
          const { data } = res;
          data.list.forEach((item) => {
            item.selected = item.selected ? true : false // 数字变布尔值
          })
          this.SelectList = data.list

          // 对象数组去重
          this.Selected = Array.from(
            new Set(
              (this.getSelected ? data.overview : this.Selected).map((el) =>
                JSON.stringify(el)
              )
            )
          ).map((el) => JSON.parse(el)) /* 用户的选择 */

          this.defaultSelected = data.selected /* 默认选择 */
          this.getSpanArr(this.SelectList)
          this.checkIsDufaultSelect()
          this.loading = false
          this.getSelected = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      val.selected = !val.selected
      if (val.selected) {
        if (this.Selected.length >= 8) {
          this.$message.info('最多可选择8个数据')
          val.selected = !val.selected
          return
        }
        this.Selected.push(val)
        this.checkIsDufaultSelect()
      } else {
        let index = this.Selected.findIndex(
          (item) => item.view_index === val.view_index
        )
        this.Selected.splice(index, 1)
        this.isDefaultSelect = false
      }
    },
    // 判断是否是默认选择数据
    checkIsDufaultSelect() {
      let sameCount = 0
      this.defaultSelected.forEach((item1) => {
        this.Selected.forEach((item2) => {
          if (item1 === item2.view_index) {
            sameCount++
          }
        })
      })
      this.isDefaultSelect = sameCount === this.defaultSelected.length
    },
    // 表格数据处理
    getSpanArr(data) {
      this.spanArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1)
          this.pos = 0
        } else {
          if (data[i].belong_feature === data[i - 1].belong_feature) {
            // 如果belong_feature相等就累加，并且push 0  这里是根据一样的belong_feature匹配
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            // 不相等push 1
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    // 表格合并操作
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
    // 拖拽结束处理
    onEnd() {
      console.log(this.Selected)
    },
    // 删除选中
    handleClose(data, i) {
      this.Selected.splice(i, 1)
      this.SelectList.find(
        (item) => item.view_index === data.view_index
      ).selected = false
      this.isDefaultSelect = false
    },
    // 点击默认选择
    handleSelectDefault() {
      this.isDefaultSelect = !this.isDefaultSelect
      this.Selected = []
      if (this.isDefaultSelect) {
        this.defaultSelected.forEach((item) => {
          const data = this.SelectList.find((data) => data.view_index === item)
          if (data) {
            data.selected = true
            this.Selected.push(data)
          }
        })
      } else {
        this.SelectList.forEach((item) => {
          item.selected = false
        })
      }
    },
    // 清空选择
    clearSelected() {
      this.Selected = []
      this.SelectList.forEach((item) => {
        item.selected = false
      })
      this.isDefaultSelect = false
    },
    // 筛选操作
    handleFilter() {
      this.getDataList()
    },
    // 确定
    submit() {
      if (!this.Selected.length) {
        this.$message.info('请选择数据项')
      } else {
        this.saveLoading = true
        saveSelected({ overview: this.Selected })
          .then((res) => {
            this.$message.success(res.msg)
            this.clearDialog()
          })
          .catch((err) => {
            this.saveLoading = false
          })
      }
    },
    // 清除弹窗数据
    clearDialog() {
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      this.upload()
      this.saveLoading = false
      this.openDialog = false
      this.keyword = ''
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.keyword = ''
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 30%;
  display: flex;
  margin-left: auto;
  margin-bottom: 28px;
  margin-top: 9px;
  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}

.selected {
  display: flex;
  margin-top: 16px;

  .label {
    font-size: 14px;
    width: 120px;
    color: #606266;
  }

  .tag {
    display: flex;
    flex-wrap: wrap;
    .tag-item {
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
    }
  }
}

.select-btn {
  margin-top: 18px;

  .el-button + .el-button {
    margin-left: 20px;
  }
}

.dialog-vertical {
  ::v-deep.el-checkbox.is-disabled.is-checked .el-checkbox__inner {
    color: #ffffff;
    background-color: #3576ff;
    border-color: #3576ff;
    cursor: pointer;
  }
  ::v-deep.el-checkbox.is-disabled .el-checkbox__inner {
    color: #ffffff;
    background-color: #ffffff;
    border-color: #dcdfe6;
    cursor: pointer;
    &::after {
      cursor: pointer;
      border-color: #dcdfe6;
    }
  }
}
</style>
