import api from "@/base/utils/request";

//获取首页数据
export const getCycIndex = data => {
  return api({
    url: "/admin/cyc/overview/index",
    method: "post",
    data,
    allowMainLoading: true,
  });
};

// 获取待处理任务列表
export const getTaskList = data => {
  return api({
    url: "/admin/cyc/overview/tabPending",
    method: "post",
    data
  });
};

// 获取自定义状态数据
export const StatusList = data => {
  return api({
    url: "/admin/cyc/overview/realtime",
    method: "post",
    data
  });
};

// 保存选择的数据类型
export const saveSelected = data => {
  return api({
    url: "/admin/cyc/overview/realtimeSave",
    method: "post",
    data
  });
};

// 获取功能编辑数据
export const FeaturesList = data => {
  return api({
    url: "/admin/cyc/overview/myFeatures",
    method: "post",
    data
  });
};

// 保存我的功能
export const saveFeatures = data => {
  return api({
    url: "/admin/cyc/overview/functionSave",
    method: "post",
    data
  });
};

// 获取微信小程序信息
export const AuthInfo = data => {
  return api({
    url: "/admin/wx_open/wx_applet_auth/getAuthInfo",
    method: "post",
    data
  });
};

/**
 * 数据报告入口
 */
export const getDataReportEntrance = () => {
  return api({
    url: '/admin/cyc/overview/dataReport',
    method: 'post'
  })
}
/**
 * 更新日志列表
 */
export const updateLogs = () => {
  return api({
    url: '/admin/admin/overView/updateLogs',
    method: 'post'
  })
}
/**
 * 最新一篇更新日志
 */
export const latestUpdateLog = () => {
  return api({
    url: '/admin/admin/overView/latestUpdateLog',
    method: 'post'
  })
}
/**
 * 概览最新的资讯
 */
export const latestArticles = () => {
  return api({
    url: '/admin/admin/overView/latestArticles',
    method: 'post'
  })
}

export const getNewWorkStation = data => {
  return api({
    url: "/admin/admin/nav/newWorkStation",
    method: "post",
    data
  });
}

export const saveStationList = data => {
  return api({
    url: "/admin/admin/nav/saveStationList",
    method: "post",
    data
  });
}
