<template>
  <div class="list-content">
<!--
      :style="
        index <
        featuresList.length -
          (featuresList.length % 4 ? featuresList.length % 4 : 4)
          ? 'margin-bottom: 26px;'
          : ''
      "
-->
    <div
      class="list-item cursor-pointer"
      v-for="(item, index) in featuresList"
      :key="index"
      @click="jumpConfig(item)"
    >
      <el-badge
        :hidden="!item.msg_count"
        :max="99"
        :value="item.msg_count"
        class="item-badge"
      >
        <el-image class="item-icon" :src="item.icon"></el-image>
      </el-badge>
      <div class="item-content">
        <p class="name">{{ item.name }}</p>
        <p class="desc" v-if="item.description">{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    featuresList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    jumpConfig(config) {
      /**
       * 引用的话：这里需要注意一下，使用params进行传参，在新页面内使用this.$route.params对象为{}，参数无法传过来，query是可以正常传参。
       如果遇到这种情况，不想参数在url上显示且必须要传的话，可以借用浏览器的缓存来实现。
       在父页面写入缓存，在子页面从缓存中读取，读取之后再删除。
       */
      const { href } = this.$router.resolve({
        name: config.jump_link,
        params: config.param ? config.param : {}, // 没用的，这个传不了给新窗口
        query: config.query ? config.query : {},
      })
      window.open(href, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.list-content {
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 25%));
  grid-row-gap: 26px;

  padding-left: 26px;

  .list-item {
    /*width: 25%;*/
    display: flex;
    .item-icon {
      width: 50px;
      height: 50px;
      border-radius: 4px;
    }
    .item-badge {
      ::v-deep.el-badge__content {
        right: 20px;
        top: 5px;
      }
    }
    .item-content {
      margin-left: 12px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;

      .name {
        width: 112px;
        font-size: 14px;
        line-height: 18px;
        color: #3a3a3a;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .desc {
        width: 112px;
        height: 38px;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
