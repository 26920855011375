<template>
  <div class="index">
    <div class="index-box">
      <div class="content-right flex-col" v-if="!loading">
        <div class="config" v-if="list && list.realtime">
          <div class="flex-align-center">
            <h1>实时概况</h1>
            <span class="update-time">更新时间：{{ list.update_time }}</span>
            <el-button
              type="text"
              class="right-top-btn"
              @click="openStatusSelector"
              >编辑</el-button
            >
          </div>
          <div class="row">
            <div
              class="col-md-3"
              v-for="(item, index) in list.realtime"
              :key="index"
            >
              <div v-if="item.type === 0">
                <p class="p-gray">{{ item.name }}</p>
                <p class="p-black-big">
                  {{ item.today }}
                  <span v-if="item.today < item.yesterday">
                    <img src="../assets/images/xiajiang.png" />
                  </span>
                  <span v-if="item.today > item.yesterday">
                    <img src="../assets/images/tisheng.png" />
                  </span>
                </p>
                <p class="p-grays">昨日：{{ item.yesterday }}</p>
              </div>
              <div v-if="item.type === 1">
                <p class="p-gray">{{ item.name }}</p>
                <p class="p-black-big">
                  {{ item.count }}
                </p>
              </div>
            </div>
            <el-divider
              direction="vertical"
              v-if="list.realtime.length > 2"
            ></el-divider>
          </div>
        </div>
        <div
          class="config"
          v-if="list && list.foursteps.fourSteps.length"
          style="padding: 0"
        >
          <div class="card header-left">
            <div style="display: flex; padding: 27px 21px 0">
              <h1>四步开启线上管理云平台</h1>
              <span class="title-tip"
                >已完成 {{ list.foursteps.complete }}/4 步</span
              >
              <el-button type="text" class="right-top-btn" @click="handleOpen"
                >{{ isOpen ? '收起' : '展开'
                }}<span
                  class="icon-style el-icon-arrow-up"
                  :style="isOpen ? '' : 'transform:rotate(180deg);'"
                ></span>
              </el-button>
            </div>
            <div
              class="card-content"
              :style="isOpen ? 'height: 295px;' : 'height: 27px;'"
            >
              <four-steps :stepArr="list.foursteps.fourSteps"></four-steps>
              <img
                v-if="isOpen"
                class="bg-image bg1"
                src="../assets/images/step-bg1.png"
              />
              <img
                v-if="isOpen"
                class="bg-image bg2"
                src="../assets/images/step-bg2.png"
              />
              <img
                v-if="isOpen"
                class="bg-image bg3"
                src="../assets/images/step-bg3.png"
              />
            </div>
          </div>
        </div>
        <!-- 已经不用了 -->
        <div class="config" v-if="list && pedingList && false">
          <div class="card header-left">
            <div style="display: flex">
              <h1>待处理任务</h1>
            </div>
            <el-tabs
              class="task-tab"
              v-model="activeName"
              type="card"
              @tab-click="handleClickTab"
            >
              <el-tab-pane
                :label="`全部 (${all_task_count})`"
                name="normal"
              ></el-tab-pane>
              <el-tab-pane
                v-for="(tab, index) in someList"
                :name="tab.tab_index"
                :key="index"
              >
                <div class="tab-label" slot="label">
                  <span>{{ tab.name }}</span>
                  <span v-if="tab.count" class="task-dots"></span>
                </div>
              </el-tab-pane>
              <el-tab-pane v-if="pedingList.length > tab_limit"
                ><span class="tab-label" slot="label">
                  <span
                    class="task-dots"
                    v-if="
                      pedingList
                        .slice(tab_limit)
                        .findIndex((item) => item.count !== 0) !== -1
                    "
                  ></span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom-start"
                    @command="handleClickRow"
                  >
                    <span
                      :style="isClickMore ? 'color: #3576FF' : 'color: #000'"
                      class="el-dropdown-link"
                    >
                      更多<i
                        style="margin-left: 4px"
                        class="el-icon-arrow-down"
                      ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(item, index) in moreList"
                        :key="index"
                        :command="item.tab_index"
                      >
                        <p>
                          <span>{{ item.name }}</span>
                          <span>{{ item.count }}个待处理</span>
                        </p>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </el-tab-pane>
            </el-tabs>
            <div style="margin: auto" class="main">
              <task-list
                v-model="task_list"
                :page="page"
                :update="updateList"
                :isResetScroll="isResetScroll"
                v-loading="taskLoading"
              >
              </task-list>
            </div>
          </div>
        </div>
        <div class="config flex-1" v-loading="!WorktationList.length">
          <div class="card header-left">
            <div style="display: flex">
              <h1>工作台</h1>
              <el-button type="text" class="right-top-btn" @click="openWorktationEdit = true"
                >编辑
             </el-button>
            </div>
            <div style="margin-top: 25px" v-if="WorktationList.length">
              <features-list :featuresList="WorktationList.filter((t) => t.status)"></features-list>
            </div>
          </div>
        </div>
        <div
          class="config flex-1"
          v-if="list && list.my_features && false"
          v-loading="loading"
        >
          <div class="card header-left">
            <div style="display: flex">
              <h1>我的功能</h1>
              <el-button type="text" class="right-top-btn" @click="EditFeatures"
                >编辑
              </el-button>
            </div>
            <div style="margin-top: 25px" v-if="list.my_features.length">
              <features-list :featuresList="list.my_features"></features-list>
            </div>
          </div>
        </div>
        <div
          class="config flex-1"
          v-if="list && list.recommend_features.length && false"
        >
          <div class="card header-left">
            <div style="display: flex">
              <h1>更多功能</h1>
            </div>
            <div style="margin-top: 25px">
              <features-list
                :featuresList="list.recommend_features"
              ></features-list>
            </div>
          </div>
        </div>
        <div class="version-container" v-if="list">
          <div class="update-version">{{ list.system_explain }}</div>
          <!--      <div class="version">系统版本：{{list.version}}<span v-if="list.version">(获取新版本{{list.version}})</span></div>-->
          <!--          <div class="version">系统版本：{{list.system_version}}</div>-->
        </div>
      </div>
      <div class="content-left" v-if="!loading">
        <div
          class="content-box"
          style="padding: 30px 20px !important"
          v-if="list && list.account_info"
        >
          <object-info :infoData="list.account_info"></object-info>
        </div>
        <!-- 资讯中心 -->
        <div
          class="content-box"
          v-if="articlesData.articles && articlesData.articles.length"
        >
          <Articles :listData="articlesData"></Articles>
        </div>
        <!-- 更新日志 -->
        <div class="content-box" v-if="updateLogList.length">
          <update-log :listData="updateLogList"></update-log>
        </div>
        <div class="content-box">
          <p class="box-title">人工服务</p>
          <div class="qr-code">
            <img
              class="code-img"
              src="../assets/images/qr-code-kf.5c030084.png"
              fit="cover"
            />
          </div>
        </div>
        <div class="content-box">
          <div
            class="content-item"
            @click="
              jumpLink('https://www.yuque.com/ax101w/yz6xgk/ayilv9', '操作手册')
            "
          >
            <img class="item-icon" src="../assets/images/green-book.png" />
            <div class="item-text">
              <p style="margin-bottom: 6px">操作手册</p>
              <p style="font-weight: 300">详细产品帮助文档</p>
            </div>
          </div>
        </div>
        <div class="content-box">
          <div
            class="content-item"
            @click="
              jumpLink('https://support.qq.com/products/301909', '意见与反馈')
            "
          >
            <img class="item-icon" src="../assets/images/blue-chat.png" />
            <div class="item-text">
              <p style="margin-bottom: 6px">意见与反馈</p>
              <p style="font-weight: 300">我们很期待您的宝贵意见</p>
            </div>
          </div>
        </div>
      </div>
      <status-selector
        v-model="openStatusSelect"
        :upload="getIndexData"
      ></status-selector>
      <features-edit
        v-model="openFeaturesEdit"
        :upload="getIndexData"
      ></features-edit>
      <worktation-edit 
       v-model="openWorktationEdit"
      :upload="getNewWorkStation"
      />
    </div>
    <div class="fixed-right-bottom cursor-pointer">
      <el-popover
        @show="getCodes"
        placement="top-end"
        title=""
        width="220"
        trigger="hover"
      >
        <div slot="reference" class="fixed-item flex-col flex-center">
          <img class="item-icon" src="../assets/images/analysis.png" />
          <div class="item-name">数据报告</div>
        </div>
        <!--
        "mp_code":"",
        "app_code":""//小程序未授权时返回null
        -->

        <div class="overview-codes flex-col" v-loading="codeLoading">
          <template v-if="codesData">
            <div v-if="isH5" class="flex-col flex-align-center">
              <img class="qrcode" :src="codesData.mp_code" />
              <span>H5 微网站</span>
            </div>
            <div
              v-if="isWxApplet"
              class="flex-col flex-align-center"
              style="margin-top: 20px"
            >
              <img class="qrcode" :src="codesData.app_code" />
              <span>微信小程序</span>
            </div>
          </template>
          <div v-else-if="!codeLoading" class="flex-center none">暂无数据</div>
        </div>
      </el-popover>
    </div>
    <UpdateLogDialog
      v-model="openUpdateLog"
      :content="openUpdateLogData"
    ></UpdateLogDialog>
  </div>
</template>
<script>
import {
  getCycIndex,
  getDataReportEntrance,
  getTaskList,
  updateLogs,
  latestUpdateLog,
  latestArticles,
  getNewWorkStation,
} from '../api/index-config'
import { formatDate } from '@/base/utils/tool.js'
import StatusSelector from '../components/StatusSelector.vue'
import FourSteps from '../components/FourSteps.vue'
import ObjectInfo from '../components/ObjectInfo.vue'
import TaskList from '../components/TaskList.vue'
import FeaturesList from '../components/FeaturesList.vue'
import WorktationEdit from '../components/WorktationEdit.vue'

import FeaturesEdit from '../components/FeaturesEdit.vue'
import UpdateLog from '../components/UpdateLog.vue'
import UpdateLogDialog from '../components/UpdateLogDialog.vue'
import Articles from '../components/Articles.vue'

export default {
  components: {
    StatusSelector,
    FourSteps,
    ObjectInfo,
    TaskList,
    FeaturesList,
    WorktationEdit,
    FeaturesEdit,
    UpdateLog,
    UpdateLogDialog,
    Articles,
  },
  data() {
    return {
      loading: false,
      taskLoading: false,
      codeLoading: false,
      list: null,
      pedingList: [],
      task_list: [],
      all_task_count: 0,
      tab_limit: 5,
      update_time: '',
      activeName: 'normal',
      page: 1,
      openStatusSelect: false,
      openFeaturesEdit: false,
      isOpen: true,
      isClickMore: false,
      isResetScroll: false,
      requestable: true,
      // pageReset: false,
      // 是否为测试版
      isTestVer:
        window.serverConfig.VUE_APP_COPYCONFIG_BASEURL === document.domain,
      codesData: null,
      updateLogList: [],
      articlesData: {},
      openUpdateLog: false,
      openUpdateLogData: {},
      openWorktationEdit:false,
      WorktationList: [],
    }
  },
  computed: {
    moreList() {
      return this.pedingList.filter((item, index) => index >= this.tab_limit)
    },
    someList() {
      return this.pedingList.filter((item, index) => index < this.tab_limit)
    },
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    },
    isH5() {
      return this.$store.getters.tenant.features.includes('h5')
    },
  },
  methods: {
    // 获取最新一篇日志数据，并且弹窗
    getLaseUpdateLog() {
      latestUpdateLog().then((res) => {
        this.openUpdateLogData = res.data
        if (this.openUpdateLogData) {
          this.openUpdateLog = true
        }
      })
    },
    // 获取日志数据
    getUpdateLogList() {
      updateLogs()
        .then((res) => {
          this.updateLogList = res.data
        })
        .catch(() => {})
    },
    // 获取资讯中心
    getArticlesList() {
      latestArticles()
        .then((res) => {
          this.articlesData = res.data
        })
        .catch(() => {})
    },
    getCodes() {
      if (this.codesData || this.codeLoading) return
      this.codeLoading = true
      getDataReportEntrance()
        .then((res) => {
          this.codeLoading = false
          this.codesData = res.data
        })
        .catch(() => {
          this.codeLoading = false
        })
    },
    // 获取页面数据
    getIndexData() {
      this.loading = true
      // Promise.all([, ])
      getCycIndex()
        .then((res) => {
          this.list = res.data
          this.pedingList = res.data.pending
          this.update_time = formatDate(new Date())
          // this.handleGetTaskList({ page: 1, tab_index: this.activeName })
          this.loading = false
          this.getNewWorkStation()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取工作台列表
    getNewWorkStation() {
      this.WorktationList = []
      getNewWorkStation().then((res) => {
        this.WorktationList = res.data.all.map((t) => {
          return {
            name: t.label,
            query: t.query,
            feature_sign: t.ket,
            icon: t.icon,
            jump_link: t.url,
            msg_count: t.msg_count,
            status:t.status
          }
        })
      })
    },
    // 点击概况编辑
    openStatusSelector() {
      this.openStatusSelect = true
    },
    // 点击切换tab
    handleClickTab(e) {
      if (Number(e.index) <= this.tab_limit) {
        this.page = 1
        this.requestable = true
        let requestData = {
          page: 1,
          tab_index: e.name,
        }
        this.isResetScroll = true
        this.handleGetTaskList(requestData)
        this.isClickMore = false
      } else {
        this.isClickMore = true
      }
    },
    // 获取任务列表
    handleGetTaskList(requestData) {
      if (!this.requestable) return
      this.taskLoading = true
      getTaskList(requestData)
        .then((res) => {
          const { data } = res
          this.activeName = data.tab
          this.page = data.page
          if (requestData.page === 1) {
            this.task_list = data.data
          } else if (requestData.page > 1) {
            this.task_list = [...this.task_list, ...data.data]
          }
          if (data.data.length < 10) {
            this.requestable = false
          }
          this.all_task_count =
            data.tab === 'normal' ? data.data.length : this.all_task_count
          this.taskLoading = false
          this.isResetScroll = false
        })
        .catch((err) => {
          this.taskLoading = false
          this.isResetScroll = false
        })
    },
    // 任务列表触底刷新
    updateList(page) {
      this.page = page
      if (this.activeName !== 'normal')
        this.handleGetTaskList({ page, tab_index: this.activeName })
    },
    //点击更多
    handleClickRow(e) {
      this.requestable = true
      this.page = 1
      this.isResetScroll = true
      this.handleGetTaskList({ page: 1, tab_index: e })
    },
    // 模块展开收起
    handleOpen() {
      this.isOpen = !this.isOpen
    },
    // 编辑我的功能
    EditFeatures() {
      this.openFeaturesEdit = true
    },
    //侧边模块跳转
    jumpLink(url, name) {
      window.open(url, '_blank')
    },
  },
  created() {
    this.getIndexData()
    console.debug('document.domain: ', document.domain)
    console.debug('isTestVer: ', this.isTestVer)
    console.debug(
      'window.serverConfig.VUE_APP_COPYCONFIG_BASEURL: ',
      window.serverConfig.VUE_APP_COPYCONFIG_BASEURL
    )
    // this.getCodes();
    this.getUpdateLogList()
    this.getArticlesList()
    this.getLaseUpdateLog()
  },
  mounted() {
    document.getElementById('main-container').style.padding = '0 0 20px 0'
    document.getElementById('main-container').style.marginBottom = '40px'
  },
  beforeDestroy() {
    // this.pageReset = false;
    document.getElementById('main-container').style.padding = '20px'
    document.getElementById('main-container').style.marginBottom = '20px'
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.fixed-right-bottom {
  position: fixed;
  right: 16px;
  bottom: 73px;
}

.fixed-item {
  width: 72px;
  height: 72px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12);
  padding: 11px;

  .item-icon {
    width: 28px;
    height: 28px;
  }

  .item-name {
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    line-height: 1.5;
    margin-top: 4px;
  }
}
</style>
<style lang="scss">
.overview-codes {
  /*height: 480px;*/
  min-height: 200px;

  .none {
    height: 100%;
  }
  .qrcode {
    width: 100%;
    margin: 0 $space;
  }
}
</style>
