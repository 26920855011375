<template>
    <div class="content">
      <div class="title-box">
        <div class="left">资讯中心</div>
        <div class="right">
          <!-- 默认跳第一篇 -->
          <a :href="listData.more_url" target="_blank">
            <i class="el-icon-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="item-list">
        <div class="item" v-for="(item, i) in listData.articles.slice(0, 4)">
          <el-link :underline="false" :href="item.link" target="_blank">
            <div class="item-title">
              {{ item.title }}
            </div>
          </el-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    components: {},
    props: {
      listData: {
        type: Object,
        require: true,
      },
    },
    data() {
      return {
    
      };
    },
    mounted() {
  
    },
    methods: {
  
    },
  };
  </script>
  
  <style lang="scss" scoped>
  a {
    text-decoration: none;
  }
  .content {
    font-family: "PingFang SC";
  
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 18px;
        font-weight: 500;
        color: #000;
      }
  
      .right {
        i.el-icon-arrow-right {
          font-size: 16px;
          color: #d9d9d9;
          font-weight: 700;
        }
      }
    }
    .item-list {
      .item {
        margin-top: 12px;
        .item-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          @include clamp(1);
        }
  
        .html-content {
          margin-top: 8px;
          padding: 12px;
          border-radius: 4px;
          background: #f9f9f9ff;
  
          .text {
            @include clamp(3);
            color: #808080;
            font-size: 12px;
            line-height: 21.6px;
          }
        }
      }
    }
  }
  </style>
  