<template>
  <el-dialog title="功能快捷入口" :visible.sync="openDialog" width="808px" :show-close="true"
    class="dialog-vertical" @close="cancel">
    <div class="main-content" :style="loading?'height: 550px':''" v-loading="loading">
      <div class="selected">
        <p class="label">
          <span>已选择数据类型：(可直接拖动调整顺序)</span>
          <el-input style="margin-left:auto;width: 30%" size="medium" v-model="keyword" placeholder="搜索"
            @change="handleFilter">
            <i class="el-icon-search el-input__icon" slot="suffix"></i>
          </el-input>
        </p>
        <draggable v-model="Selected" :sort="true" forceFallback="true" group="view_index" animation="300">
          <transition-group class="features">
            <div class="feature-item" v-for="(item, index) in Selected" :key="item.name">
              <el-image :src="item.icon" class="item-icon"></el-image>
              <p class="item-name">{{item.name}}</p>
              <p class="icon-btn el-icon-error" @click="handleClose(item,index)"></p>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="select-list">
        <div class="features-list" v-for="(parent, index) in NoSelectedList" :key="parent.id">
          <p class="feature-list-title">{{parent.name}}</p>
          <div class="children-list">
            <div class="feature-item" v-for="(children, index) in parent.feature.filter(f => !f.is_selected)" :key="children.id"
                 @mouseover="showPlusIcon = children.id" @mouseleave="showPlusIcon = -1">
              <el-image :src="children.icon" class="item-icon"></el-image>
              <p class="item-name">{{children.name}}</p>
              <p class="icon-btn el-icon-circle-plus" v-if="showPlusIcon === children.id"
                @click="AddFeature(children)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { FeaturesList, saveFeatures } from "../api/index-config";
import draggable from "vuedraggable";
export default {
  components: { draggable },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false, //加载
      SelectList: [],
      Selected: [],
      keyword: "", //关键词
      saveLoading: false,
      getSelected: true,
      showPlusIcon: -1,
    };
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.loading = true;
      FeaturesList({ keyword: this.keyword })
        .then((res) => {
          const { data } = res;
          this.SelectList = data.my_features;
          this.Selected = this.getSelected
            ? data.selected_features
            : this.Selected;
          this.getSelected = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 添加功能
    AddFeature(val) {
      if (this.Selected.length >= 8) {
        this.$message.info("最多可选择8个数据");
      } else {
        val.is_selected = 1;
        this.Selected.push(val);
      }
    },
    // 删除选中
    handleClose(val, i) {
      this.Selected.splice(i, 1);
      this.SelectList.forEach((item1) => {
        item1.feature.forEach((item2) => {
          item2.is_selected =
            val.feature_sign === item2.feature_sign ? 0 : item2.is_selected;
        });
      });
    },
    // 筛选操作
    handleFilter() {
      this.getDataList();
    },
    // 确定
    submit() {
      if (!this.Selected.length) {
        this.$message.info("请选择数据项");
      } else {
        this.saveLoading = true;
        saveFeatures({ function: this.Selected })
          .then((res) => {
            this.$message.success(res.msg);
            this.clearDialog();
          })
          .catch((err) => {
            this.saveLoading = false;
          });
      }
    },
    // 清除弹窗数据
    clearDialog() {
      this.upload();
      this.saveLoading = false;
      this.openDialog = false;
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getDataList();
      } else {
        this.getSelected = true;
        this.keyword = "";
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    NoSelectedList() {
      return this.SelectList.filter(el => el.feature.findIndex(f => f.is_selected === 0) !== -1)
    }
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  max-height: 550px;
  transition: all 0.5s ease;
  .select-list {
    max-height: 290px;
    overflow-y: auto;
    overflow-x: hidden;
    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      width: 10px;
      /**/
    }
    &::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(160, 160, 160);
    }
    &::-webkit-scrollbar-corner {
      background: #179a16;
    }
  }
}

.selected {
  margin-top: 16px;

  .label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #606266;
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    margin-top: 27px;
    margin-bottom: 10px;
    .feature-item {
      width: 24%;
      margin-right: 1%;
      padding: 16px 10px 16px 15px;
      background: #f7f8fa;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .item-icon {
        width: 50px;
        height: 50px;
        margin-right: 12px;
      }

      .item-name {
        width: 93px;
        font-size: 14px;
        color: #3a3a3a;
      }

      .icon-btn {
        position: absolute;
        top: 4px;
        right: 4px;
        color: #ff5350;
        font-size: 14px;
      }
    }
  }
}

.features-list {
  .feature-list-title {
    padding: 8px 0;
    font-size: 14px;
    color: #3a3a3a;
  }

  .children-list {
    display: flex;
    flex-wrap: wrap;
    .feature-item {
      width: 24%;
      margin-right: 1%;
      padding: 16px 10px 16px 15px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        background: #f7f8fa;
      }

      .item-icon {
        width: 50px;
        height: 50px;
        margin-right: 12px;
      }

      .item-name {
        width: 93px;
        font-size: 14px;
        color: #3a3a3a;
      }

      .icon-btn {
        position: absolute;
        top: 4px;
        right: 4px;
        color: #3479ef;
        font-size: 14px;
      }
    }
  }
}
</style>
