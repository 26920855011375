<template>
  <el-dialog :title="dialog_title" :visible.sync="openDialog" :width="type==='wechat'?'632px':'358px'"
    class="dialog-vertical" close-on-click-modal>
    <div class="main-content" v-loading="loading">
      <div class="info" v-if="detailForm && type==='wechat'">
        <div class="info-right">
          <el-form size="medium" :model="detailForm.base_info" label-position="right" label-width="100px"
            class="form">
            <el-form-item label="小程序名称：">{{detailForm.base_info.name}}</el-form-item>
            <el-form-item label="主体信息：">{{detailForm.base_info.principal_name}}</el-form-item>
            <el-form-item label="小程序ID：">{{detailForm.base_info.authorizer_appid}}</el-form-item>
            <el-form-item label="线上版本：">
              {{detailForm.base_info.online_version}}</el-form-item>
            <el-form-item label="服务类目：">
              <div class="form-content" style="display: block">
                <p class="content-list" v-for="(item, index) in detailForm.categories" :key="index">
                  {{item.first_name + ' > ' + item.second_name}}
                </p>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="info-left">
          <div style="text-align: center">
            <el-image class="info-img cursor-pointer" :src="fixImageUrl(detailForm.base_info.qrcode_url)"
              @click="$previewImage([detailForm.base_info.qrcode_url])" fit="cover">
              <div style="width: 100%;height: 100%" slot="placeholder" v-loading="true"></div>
            </el-image>
            <!-- <div @click="$previewImage([detailForm.base_info.qrcode_url])">
              <el-avatar class="info-img cursor-pointer" :src="fixImageUrl(detailForm.base_info.qrcode_url)"
                fit="cover"></el-avatar>
            </div> -->
            <div class="handler-btn">
              <el-button @click="openAddMember = true">添加体验者</el-button>
              <el-button type="primary" @click="openQrCode = true">获取体验码</el-button>
            </div>
          </div>
        </div>
        <AddMemberDialog v-model="openAddMember" :updateList="refreshList" />
        <ExperienceQRCode v-model="openQrCode" :qrCode="detailForm.qrcode" />
      </div>
      <div class="info" v-else-if="detailForm && type!=='wechat'">
        <el-form size="medium" :model="detailForm.base_info" label-position="right" label-width="100px"
          class="form">
          <el-form-item :label="type + '名称：'">{{detailForm.base_info.name}}</el-form-item>
          <el-form-item label="版本号：">
            {{detailForm.base_info.online_version}}</el-form-item>
          <el-form-item :label="type+'链接：'">
            <div style="display: flex"><span class="item-url">klsjae-kjjosa/s=asajojeajkdas·…</span><span
                class="copy" @click="urlCopy('klsjae-kjjosa/s=asajojeajkdas·…')">复制</span>
            </div>
          </el-form-item>
          <el-form-item label="下载二维码：">
            <div class="qr-code">
              <el-image class="qrcode-img" src=""></el-image>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AddMemberDialog from "../../wechat-app/components/AddMemberDialog";
import ExperienceQRCode from "../../wechat-app/components/ExperienceQRCode";
import { AuthInfo } from "../api/index-config";
import { copyText } from "@/base/utils/tool";
export default {
  components: { ExperienceQRCode, AddMemberDialog },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      loading: false, //加载
      detailForm: null,
      openQrCode: false,
      openAddMember: false,
      dialog_title: "微信小程序信息",
    };
  },
  beforeDestroy() {
    let func = function () {};
    document.removeEventListener("touchmove", func);
  },
  methods: {
    // 获取微信小程序信息
    getAuthInfo() {
      this.loading = true;
      AuthInfo()
        .then((res) => {
          const { data } = res;
          this.detailForm = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 复制链接
    urlCopy(url) {
      copyText(url).then((valid) => {
        this.$message.success("复制成功");
      });
    },
    refreshList() {},
    //停止页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    //开启页面滚动
    Move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true });
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getAuthInfo();
        this.stopMove();
      } else {
        this.Move();
      }
    },
    type(val) {
      console.log(val);
      switch (val) {
        case "wechat":
          this.dialog_title = "微信小程序信息";
          break;
        case "App":
          this.dialog_title = "App信息";
          break;
        case "H5":
          this.dialog_title = "H5 微网站信息";
          break;
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  min-height: 242px;
  .info {
    display: flex;
    padding-bottom: 20px;
    .info-right {
      width: 50%;
    }
    .info-left {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .info-img {
        background-color: #fff;
        width: 170px;
        height: 170px;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 28px;
      }

      .handler-btn {
        button + button {
          margin-left: 20px;
        }
      }
    }
  }
  .form-content {
    display: flex;
    align-items: center;
    flex: 1;
    // padding-right: 85px;

    .content-text {
      font-size: 14px;
      line-height: 36px;
      color: rgba(0, 0, 0, 0.65);
    }

    .content-list + .content-list {
      margin-top: -8px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;

    .item-url {
      width: 180px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .copy {
      color: #3479ef;
      cursor: pointer;
    }

    .qr-code {
      width: 152px;
      height: 152px;
      margin-top: 10px;
      padding: 13px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;

      .qrcode-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
