<template>
  <div v-if="infoData">
    <div class="box-top">
      <el-image :src="infoData.icon" class="info-icon"></el-image>
      <p class="info-name">{{infoData.name}}</p>
      <el-button type="text" @click="toIframe">查看详情</el-button>
    </div>
    <div v-if="isWxApplet" class="info-platform">
      <div class="platform-item">
        <p>
          微信小程序：<span :style="infoData.is_mp?'color: #13CE66':''">{{infoData.is_mp?'已绑定':'未开通'}}</span></p>
        <el-button type="text" @click="handleDetail('AppletBaseInfo','wechat')">
          {{infoData.is_mp?'查看版本':'去开通'}}
        </el-button>
      </div>
    </div>
    <div class="base-info">
      <div class="info-item">
        <p>存储空间：{{infoData.disk}}
          <!-- <el-popover placement="bottom" width="200" trigger="hover"
            :content="`有效期为：${infoData.year}`">
            <span style="color: #3479EF;margin-left: 7px" class="el-icon-question" slot="reference"></span>
          </el-popover> -->
        </p>
<!--        <el-button type="text" @click="goToPay">去购买</el-button>-->
      </div>
    <template v-if="!infoData.project_type">
      <div class="info-item">
        <p>短信条数：{{infoData.sms}}条
          <!-- <el-popover placement="bottom" width="200" trigger="hover"
            :content="`有效期为自购买${infoData.year}年内有效`">
            <span style="color: #3479EF;margin-left: 7px" class="el-icon-question" slot="reference"></span>
          </el-popover> -->
        </p>
        <el-button type="text" @click="goToPay">去购买</el-button>
      </div>
    </template>
      <template v-else>
        <div  class="info-item"> 
          <p>国内短信：{{infoData.sms}}条</p>
            <el-button type="text" @click="goToPay">去购买</el-button>
        </div>
        <div class="info-item">
          <p>国际短信：{{infoData.global_sms}}条</p>
        </div>
      </template>
      <div class="info-item">
        <p>套餐版本：{{infoData.package_name}}</p>
      </div>
      <div class="info-item">
        <p>服务期至：{{infoData.project_expire_time}}</p>
      </div>
    </div>
    <div class="overdue-tip">
      <div class="tip-item">
        <!-- <el-button v-if="infoData.expire_features" type="danger" plain>
          {{infoData.expire_features}}<span style="margin-left: 8px" class="el-icon-arrow-right"></span>
        </el-button> -->
        <el-button v-if="infoData.expire_package" type="danger" plain @click="handleRenew">
          {{infoData.expire_package}}<span style="margin-left: 8px" class="el-icon-arrow-right"></span>
        </el-button>
      </div>
    </div>
    <info-detail v-model="openVersionDetail" :type="openType"></info-detail>
  </div>
</template>

<script>
import InfoDetail from "./InfoDetail.vue";
export default {
  components: {
    InfoDetail,
  },
  props: {
    infoData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openVersionDetail: false,
      openType: "",
    };
  },
  computed: {
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    },
  },
  methods: {
    toIframe() {
      this.$router.push({
        name: 'MyProjectManage',
        params: {
          id: this.$store.state.tenant.id,
          type: 'basic_information'
        }
      })
    },
    handleDetail(routeName, type) {
      if (!this.infoData.is_mp) {
        this.$router.push({ name: routeName });
      } else {
        this.openVersionDetail = true;
        this.openType = type;
      }
    },
    // 点击去购买
    goToPay() {
      // this.handleJumpUserAdmin("Home");
      this.$router.push({
        name: 'UserCenterExtrasSms',
        params: {
          project_id: this.$store.state.tenant.id || ''
        }
      })
    },
    handleRenew() {
      this.$router.push({
        name: 'MyProjectRenew',
        params: {
          id: this.$store.state.tenant.id
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.box-top {
  padding: 0 14px;
  display: flex;
  align-items: center;

  .info-icon {
    flex-shrink: 0;
    width: 49px;
    height: 49px;
    margin-right: 9px;
    border-radius: 2px;
  }
  .info-name {
    font-size: 14px;
    color: #3a3a3a;
    flex: 1;
  }
}

.info-platform {
  background: #f7f8fa;
  padding: 20px 16px;
  margin-top: 24px;
  .platform-item {
    display: flex;
    justify-content: space-between;

    p {
      color: #3a3a3a;
      font-size: 14px;
    }

    .el-button {
      padding: 0;
      font-size: 14px;
      color: #3479ef;
    }
  }
}

.base-info {
  padding: 20px 16px;
  .info-item {
    display: flex;
    justify-content: space-between;

    p {
      color: #3a3a3a;
      font-size: 14px;
    }

    .el-button {
      padding: 0;
      font-size: 14px;
      color: #3479ef;
    }
  }

  .info-item + .info-item {
    margin-top: 17px;
  }
}

.overdue-tip {
  .el-button {
    width: 100%;
  }

  .el-button + .el-button {
    margin-top: 16px;
  }
}
</style>
