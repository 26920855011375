<template>
  <el-dialog v-if="openDialog" title="添加体验成员" :visible.sync="openDialog" width="450px" class="dialog-vertical"
    @close="cancel('wechat_id')" append-to-body>
    <el-form size="medium" :model="_data" :rules="rules" label-position="right" label-width="100px"
      class="small-form" ref="wechat_id">
      <el-form-item label="绑定微信号：" prop="wechat_id" style="margin-bottom: 0;">
        <el-input style="width: 95%" v-model="wechat_id" v-focus type="text" placeholder="请输入微信号"></el-input>
        <p class="info"><span class="el-icon-info"></span>支持绑定多个微信号，用 ; 符号隔开</p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="openDialog = false">取消</el-button>
      <el-button size="medium" type="primary" @click="submit('wechat_id')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addTester } from "../api/base-info.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        wechat_id: [
          { required: true, message: "请输入微信号", trigger: "blur" },
        ],
      },
      wechat_id: "",
      saveLoading: false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          addTester({ wechat_id: this.wechat_id })
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.saveLoading = false;
              this.openDialog = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-info {
  margin-right: 4px;
}
</style>
