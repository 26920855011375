import api from '@/base/utils/request';

// 获取小程序基本信息
export const authInfo = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/getAuthInfo',
    method: 'post',
    data
  });
};

//获取体验成员列表
export const testerList = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/testerList',
    method: 'post',
    data
  });
};

// 添加体验成员
export const addTester = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/bindTester',
    method: 'post',
    data
  });
};

// 删除体验成员
export const delTester = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/unbindTester',
    method: 'post',
    data
  });
};

// 取消授权
export const cancleAuth = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/cancelAppletAuth',
    method: 'post',
    data
  });
};