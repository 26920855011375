<template>
  <div class="task-list" style="height:100%">
    <el-scrollbar style="height:100%" v-loadmore="LoadMore" v-if="list.length" ref="scrollBar">
      <div class="main-item" v-for="(item,index) in list" @click="handleJump(item)">
        <a>
          <div style="width: 600px" class="item-title whole-main">
            <span style="margin-left: 10px;width: 100%" class="p-blue text-ellipsis">{{item.title}}</span>
          </div>
        </a>
        <div style="display: flex">
          <!-- <a class="ignore-btn" @click.stop="ignoreTask(index)">忽略</a> -->
          <a style="color:#3576ff;">立即前往</a>
        </div>
      </div>
    </el-scrollbar>
    <p v-if="!list.length">太棒了！暂时没有需要处理的任务～</p>
  </div>
</template>

<script>
  import debounce from "lodash/debounce";

  export default {
  model: {
    prop: "taskList",
    event: "updateList",
  },
  props: {
    taskList: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    isResetScroll: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
    },
  },
  computed: {
    list: {
      get() {
        return this.taskList;
      },
      set(val) {
        this.$emit("updateList", val);
      },
    },
  },
  watch: {
    isResetScroll(val) {
      if (val) this.resetScroll();
    },
  },
  methods: {
    // 触底刷新
    LoadMore: debounce(function () {
      this.update(this.page + 1);
    }, 200),
    // 点击忽略任务
    ignoreTask(i) {
      this.taskList.splice(i, 1);
    },
    handleJump(tasks) {
      console.log(tasks.params);
      this.$router.push({ name: tasks.jump_link, query: tasks.params });
    },
    // 重置滚动条位置
    resetScroll() {
      if (this.$refs.scrollBar) this.$refs.scrollBar.wrap.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.task-list {
  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.main-item {
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;

  .ignore-btn {
    color: #3576ff;
    margin-right: 12px;
  }
}

.main-item:hover {
  background-color: rgba(249, 250, 253, 1);
}

.main-item:hover .p-blue {
  color: #1976d2;
  font-weight: 500;
}

.main-item > .time {
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  width: 170px;
}

.item-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(33, 33, 33, 1);
  cursor: pointer;
}

.wenz {
  background: rgb(53, 118, 255);
  color: rgb(255, 255, 255);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 6px 5px 6px;
  width: 75px;
}

a {
  text-decoration: none;
}

p {
  font-size: 14px;
  padding: 14px 25px;
  color: #303133;
}
</style>
